@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    box-sizing: border-box;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  nav,
  section,
  summary {
    display: block;
  }

  audio,
  canvas,
  video {
    display: inline-block;
  }

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  [hidden] {
    display: none;
  }

  dl,
  menu,
  ol,
  ul {
    margin: 0 0 1em;
  }

  dd {
    margin: 0 0 0 1.125em;
  }

  menu,
  ol,
  ul {
    padding: 0 0 0 2.5em;
  }

  nav ul,
  nav ol {
    list-style: none;
    list-style-image: none;
  }

  img {
    max-width: 100%;
    border: 0;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  figure {
    margin: 0;
  }

  figure img {
    max-width: 100%;
  }

  form {
    margin: 0;
  }

  fieldset {
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    border: 0;
    padding: 0;
    white-space: normal;
  }

  button,
  input,
  select,
  textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
  }

  button,
  input {
    line-height: normal;
  }

  button,
  html input[type='button'],
  input[type='reset'],
  input[type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  button[disabled] {
    cursor: default;
  }

  button {
    border: none;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
  }

  input[type='checkbox'],
  input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type='search'] {
    -webkit-appearance: textfield;
    box-sizing: content-box;
  }

  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-decoration {
    appearance: none;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  textarea {
    overflow: auto;
    vertical-align: top;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  button:focus-visible,
  a:focus-visible {
    outline-offset: unset;
    text-decoration: underline;
    @apply outline outline-2 outline-liquorice-500;
  }

  html {
    font-size: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  html,
  button,
  input,
  select,
  textarea {
    @apply font-gibson;
  }

  body {
    margin: 0;
    min-height: 100vh;
    height: auto;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    @apply text-off-black-300;
  }

  label,
  input,
  button,
  select,
  textarea {
    font-size: 16px;
    line-height: 24px;
    border-radius: 4px;
  }

  label {
    cursor: pointer;
    display: block;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 3px;
    @apply text-grey-500;
  }

  input,
  textarea,
  select {
    display: block;
    margin: 0;
    height: 32px;
    width: 100%;
    font-size: 16px;
    @apply border-1 border-solid border-grey-300;
    @apply text-grey-500;
  }

  input[disabled],
  textarea[disabled],
  select[disabled] {
    cursor: not-allowed;
    @apply bg-grey-200;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
    @apply bg-white-300;
    @apply border-grey-200;
  }

  textarea {
    height: auto;
  }

  select {
    width: 100%;
  }

  a:hover {
    cursor: pointer;
  }

  b,
  strong {
    font-weight: bold;
  }
}

@layer components {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .custom-scrollbar:hover::-webkit-scrollbar {
    @apply bg-grey-50;
  }

  .custom-scrollbar:hover::-webkit-scrollbar-thumb {
    @apply bg-grey-200;
  }

  .custom-scrollbar::-webkit-scrollbar {
    height: 6px;
    color: black;
    background: transparent;
    border-radius: 10px;
    width: 50px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 10px;
  }
}

@layer utilities {
  .animation-delay-0 {
    animation-delay: 0ms;
  }
  .animation-delay-50 {
    animation-delay: 50ms;
  }
  .animation-delay-100 {
    animation-delay: 100ms;
  }
  .animation-delay-150 {
    animation-delay: 150ms;
  }
  .animation-delay-200 {
    animation-delay: 200ms;
  }
}
